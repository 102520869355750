import Resource from '../../../api/resource';
import request from '../../../utils/request';

class User extends Resource {
    constructor() {
        super('users');
    }
    getData(id){
        return request({
            url: `/users/${id}`,
            method: 'get',
        })
    }
    showUser(id){
        return request({
            url: `/users/show_user/${id}`,
            method: 'get',
        })
    }

    assignRole(id, query) {
        return request({
            url: `/users/assign_role/${id}`,
            method: 'post',
            data: query,
        })
    }

    storeToken(payload) {
        return request({
            url: `/firebase/storeToken`,
            method: 'post',
            data: payload,
        })
    }

    setPhoto(payload) {
        return request({
            url: `/archive/update/profile/${payload.id}`,
            method: 'post',
            data: payload.query,
        })
    }
    setAccessControl(payload) {
        return request({
            url: `/users/set/access/${payload.id}`,
            method: 'put',
            data: payload.query,
        })
    }

    addAccessUser(payload) {
        return request({
            url: `/users/access/${payload.id}`,
            method: 'post',
            data: payload.query,
        })
    }

    getAccessUser(id) {
        return request({
            url: `/users/access/${id}`,
            method: 'get'
        })
    }

    resetAccessUser(id) {
        return request({
            url: `/users/access/${id}`,
            method: 'delete'
        })
    }

    profile(){
        return request({
            url: '/users/profile',
            method: 'get'
        })
    }

    exportUsers(payload){
        return request({
            url: `/users/export_users`,
            method: 'post',
            responseType: "blob",
            data:payload
        });
    }

    list(payload){
        return request({
            url: `/users/list_users`,
            method: 'post',
            data: payload,
        })
    }

    listRoles(){
        return request({
            url: '/roles/list_roles',
            method: 'get'
        })
    }

    listPermissions(id){
        return request({
            url: `/roles/show_role/${id}`,
            method: 'get'
        })
    }

    userPermissions(id){
        return request({
            url: `/roles/user/${id}`,
            method: 'get'
        })
    }

    addRole(id, payload){
        return request({
            url: id ? `/roles/put_role/${id}` : `/roles/put_role`,
            method: 'post',
            data: payload,
        })
    }

    schoolLookups(){
        return request({
            url: '/lookups/school_lookups',
            method: 'get'
        })
    }

    inactiveUser(id) {
        return request({
            url: `/users/inactive_user/${id}`,
            method: 'post',
        })
    }

}

export {User as default};
