import Bulk_certificates from "@/api/bulk_certificates/bulk_certificates";
import fileDownload from "js-file-download";
import router from "@/router";

const bulk_certificates = new Bulk_certificates();

const bulk_certificatesModule = {
  namespaced: true,
  state: {
    items: [],
    modulesLookups: [],
    item: null,
    lookups: [],
    total: 0,
    load: false,
    loadItem:false,
  },
  getters: {
    items(state) {
      return state.items;
    },
    modulesLookups(state){
      return state.modulesLookups;
    },
    item(state) {
      return state.item;
    },
    load(state) {
      return state.load;
    },
    loadItem(state) {
      return state.loadItem;
    },
    total(state) {
      return state.total;
    },
    lookups(state) {
      return state.lookups;
    },
  },
  mutations: {
    SET_ITEMS: (state, certificates) => {
      state.items = certificates;
    },
    SET_MODULES_LOOKUPS: (state, Modules) => {
      state.modulesLookups = Modules;
    },
    SET_TOTAL_COURSES: (state, total) => {
      state.total = total;
    },
    SET_ITEMS_LOAD: (state, load) => {
      state.load = load;
    },
    SET_LOAD_ITEM: (state, loadItem) => {
      state.loadItem = loadItem;
    },
    SET_ITEM: (state, school) => {
      state.item = school;
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups;
    },
  },
  actions: {
    list({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        bulk_certificates.list(payload).then(response => {
          commit('SET_ITEMS', response);
          commit('SET_ITEMS_LOAD', false);
          resolve();
        });
      });
    },
    modulesLookups({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        bulk_certificates.modulesLookups().then(response => {
          commit('SET_MODULES_LOOKUPS', response.modules);
          commit('SET_ITEMS_LOAD', false);
          resolve();
        });
      });
    },
    store({ commit }, payload) {
      return new Promise((resolve, reject) => {
        bulk_certificates
          .store(payload.query)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    get({ commit , dispatch }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_ITEM', true);
        bulk_certificates.get(id)
          .then(response => {
            commit('SET_ITEM', response);
            commit('SET_LOAD_ITEM', false);
            dispatch('schools/updateBreadVale', response.name, { root: true })
            resolve(response);
          })
          .catch(error => {
            reject(error);
            commit('SET_LOAD_ITEM', false);
          });
      });
    },
    
    remove({ commit }, id) {
      return new Promise((resolve, reject) => {
        bulk_certificates
          .remove(id)
          .then(response => {
            
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    restore({ commit }, id) {
      return new Promise((resolve, reject) => {
        bulk_certificates
          .restore(id)
          .then(response => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    hardRemove({ commit }, id) {
      return new Promise((resolve, reject) => {
        bulk_certificates
          .hardRemove(id)
          .then(response => {
            
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default bulk_certificatesModule;
