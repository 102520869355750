
import Students from '@/api/students/students';
import fileDownload from "js-file-download";
import router from "@/router";

const students = new Students();

const studentsModule = {
    namespaced: true,
    state: {
        items: [],
        load: false,
        certificate:{},
        cryptCert:{},
        loadCertificate:false,
        topicsLoad: false,
        topics: [],
        feedback:[],
        activeModule: null,
        submitLoad:false,
    },
    getters: {
        items(state) {
            return state.items;
        },
        submitLoad(state) {
            return state.submitLoad;
        },
        certificate(state) {
            return state.certificate;
        },
        cryptCert(state) {
            return state.cryptCert;
        },
        loadCertificate(state) {
            return state.loadCertificate;
        },
        feedback(state) {
            return state.feedback;
        },
        load(state) {
            return state.load;
        },
        topics(state) {
            return state.topics;
        },
        topicsLoad(state) {
            return state.topicsLoad;
        },
        activeModule(state) {
            return state.activeModule;
        },
    },
    mutations: {
        SET_ITEMS: (state, items) => {
            state.items = items;
        },
        SET_SUBMIT_LOAD: (state, submitLoad) => {
            state.submitLoad = submitLoad;
        },
        SET_CERTIFICATE: (state, certificate) => {
            state.certificate = certificate;
        },
        SET_CRYPT_CERT: (state, cryptCert) => {
            state.cryptCert = cryptCert;
        },
        SET_LOAD_CERTIFICATE: (state, loadCertificate) => {
            state.loadCertificate = loadCertificate;
        },
        SET_FEEDBACK: (state, feedback) => {
            state.feedback = feedback;
        },
        SET_ITEMS_LOAD: (state, load) => {
            state.load = load;
        },
        SET_TOPICS: (state, topics) => {
            state.topics = topics;
        },
        SET_TOPICS_LOAD: (state, load) => {
            state.topicsLoad = load;
        },
        SET_ACTIVE_MODULE: (state, items) => {
            state.activeModule = items;
        }
    },
    actions: {
        listModules({ commit },payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                students.listModules(payload.query).then(response => {
                    commit('SET_ITEMS', response);
                    resolve(response);
                    commit('SET_ITEMS_LOAD', false);
                });
            });
        },
        certificate({ commit },payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOAD_CERTIFICATE', true);
                students.certificate(payload.query).then(response => {
                    commit('SET_CERTIFICATE', response.certificate);
                    commit('SET_CRYPT_CERT', response.crypt);
                    resolve(response);
                    commit('SET_LOAD_CERTIFICATE', false);
                }).catch((error)=>{
                    commit('SET_CERTIFICATE', null);
                    commit('SET_LOAD_CERTIFICATE', false);
                    reject(error)
                });
            });
        },
        listTopics({ commit },payload) {
            return new Promise((resolve, reject) => {
                commit('SET_TOPICS_LOAD', true);
                students.listTopics(payload.query).then(response => {
                    commit('SET_TOPICS', response);
                    resolve(response);
                    commit('SET_TOPICS_LOAD', false);
                });
            });
        },
        feedback({ commit },payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                students.feedback(payload.query).then(response => {
                    commit('SET_FEEDBACK', response);
                    commit('SET_ITEMS_LOAD', false);
                    resolve(response);
                });
            });
        },
        storeFeedback({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_SUBMIT_LOAD', true);
                students.storeFeedback(payload.query).then(response => {
                    commit('SET_FEEDBACK', response);
                    commit('SET_SUBMIT_LOAD', false);
                    resolve(response);
                }).catch((error)=>{
                    commit('SET_SUBMIT_LOAD', false);
                    reject(error)
                });
            });
        },
        

    }

}
export default studentsModule;