export default [
  {
    path: '/topics',
    name: 'topics',
    component: () => import('@/views/modules/topics/Topics.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Topics',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-topic',
    name: 'add_topic',
    component: () => import('@/views/modules/topics/EditTopic.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Topics',
          to: '/topics'
        },
        {
          text: 'Add Topic',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit-topic/:id',
    name: 'edit_topic',
    component: () => import('@/views/modules/topics/EditTopic.vue'),
    beforeEnter: (to, from, next) => {
      console.log('Route Query:', to.query);
      if (from.name) {
        to.meta.breadcrumb = [];
        to.meta.breadcrumb.push({
          text: 'Preview Topics', 
          href: from.path 
        });
        to.meta.breadcrumb.push({
          text: 'show_bread', 
          active: true,
        });
      }
      else{
        to.meta.breadcrumb = [];
        to.meta.breadcrumb.push(
          {
            text: 'Topics',
            to: '/topics'
          },
          {
            text: 'show_bread',
            active: true,
          },
        );
      }
      next();
    }
  },
  {
    path: '/show-topic/:id',
    name: 'show_topic',
    component: () => import('@/views/modules/topics/ShowTopic.vue'),
    meta:{
      breadcrumb: [],
    },
    beforeEnter: (to, from, next) => {
      if (from.name) {
        to.meta.breadcrumb = [];
        if(from.query && from.query.module_id){
          to.meta.breadcrumb.push({
            text: 'Preview Module', 
            href: `/learning/preview-module/${from.query.module_id}` 
          });
        }else{
          to.meta.breadcrumb.push({
            text: 'Preview Module', 
            href: from.path 
          });
        }
        to.meta.breadcrumb.push({
          text: 'show_bread', 
          active: true,
        });
      }
      else{
        to.meta.breadcrumb = [];
        to.meta.breadcrumb.push(
          {
            text: 'Topics',
            to: '/topics'
          },
          {
            text: 'show_bread',
            active: true,
          },
        );
      }
      next();
    }
  },
]
