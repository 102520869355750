import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import usersModule from './modules/user_settings/users/user';
import schoolsModule from './modules/schools/schools';
import coursesModule from './modules/courses/courses';
import modulesModule from './modules/modules/modules';
import bulk_certificatesModule from './modules/bulk_certificates/bulk_certificates';
import topicsModule from './modules/topics/topics';
import adminsModule from './modules/admins/admin';
import lessonPlansModule from './modules/lessonPlans/lessonPlans';
import globalModule from './app/index';
import teachersModule from './modules/teachers/teachers';
import studentsModule from './modules/students/students';
import notificationsModule from './modules/notifications/notifications';
import requestsModule from './modules/requests/requests';
import studentDashboard from './modules/studentDashboard/studentDashboard';
import studentDashboardModule from './modules/studentDashboard/studentDashboard';
import adminDashboardModule from './modules/adminDashboard/adminDashboard';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        packageVersion: process.env.PACKAGE_VERSION || '0'
    },
    getters: {
        appVersion: (state) => {
            return state.packageVersion
        }
    },
    modules: {
        app,
        appConfig,
        verticalMenu,
        users: usersModule,
        admins: adminsModule,
        schools: schoolsModule,
        teachers: teachersModule,
        lessonPlans: lessonPlansModule,
        global: globalModule,
        courses: coursesModule,
        modules: modulesModule,
        bulk_certificates:bulk_certificatesModule,
        topics: topicsModule,
        students: studentsModule,
        notifications: notificationsModule,
        studentDashboard: studentDashboardModule,
        adminDashboard:adminDashboardModule,
        requests: requestsModule
    },
})
