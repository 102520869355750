import LessonPlans from '@/api/lessonPlans/lessonPlans.js';
import fileDownload from "js-file-download";
import router from "@/router";

const lessonPlans = new LessonPlans();

const lessonPlansModule = {
  namespaced: true,
  state: {
    auth: null,
    item: null,
    lessonPlans: null,
    items: [],
    lookups: [],
    users_school: [],
    school_id:null,
    users_module: [],
    total: 0,
    load: false,
    lessonPlansAccess:[],
    photoData:null,
    avatar: null,
    finishData:null,
    loadFinish:null,
    loadSubmit:null,
    loadAssign:null,
    loadUserLookUps:null,
  },
  getters: {
    items(state) {
      return state.items;
    },
    finishData(state) {
      return state.finishData;
    },
    load(state) {
      return state.load;
    },
    loadUserLookUps(state) {
      return state.loadUserLookUps;
    },
    loadFinish(state) {
      return state.loadFinish;
    },
    loadSubmit(state) {
      return state.loadSubmit;
    },
    loadAssign(state) {
      return state.loadAssign;
    },
    total(state) {
      return state.total;
    },
    status(state) {
      return state.item.removed;
    },
    lookups(state) {
      return state.lookups;
    },
    usersSchool(state){
      return state.users_school
    },
    school_id(state){
      return state.school_id;
    },
    usersModule(state){
      return state.users_module
    },
    lessonPlansAccess(state){
      return state.lessonPlansAccess;
    },
    lessonPlans(state){
      return state.lessonPlans;
    },
  },
  mutations: {
    SET_FINISH_DATA: (state, finishData) => {
      state.finishData = finishData;
    },
    SET_lessonPlans: (state, lessonPlans) => {
      state.items = lessonPlans;
    },
    
    SET_TOTAL_lessonPlans: (state, total) => {
      state.total = total;
    },
    SET_LOAD_USER_LOOKUPS: (state, loadUserLookUps) => {
      state.loadUserLookUps = loadUserLookUps;
    },
    SET_lessonPlans_LOAD: (state, load) => {
      state.load = load;
    },
    SET_FINISH_LOAD: (state, loadFinish) => {
      state.loadFinish = loadFinish;
    },
    SET_LOAD_SUBMIT: (state, loadSubmit) => {
      state.loadSubmit = loadSubmit;
    },
    SET_LOAD_ASSIGN: (state, loadAssign) => {
      state.loadAssign = loadAssign;
    },
    SET_SCHOOL: (state, school) => {
      state.item = school;
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups;
    },
    SET_lessonPlans_ACCESS(state,lessonPlansAccess){
      state.lessonPlansAccess=lessonPlansAccess;
    },
    SET_USERS_SCHOOL(state,usersSchool){
      state.users_school = usersSchool;
    },
    SET_USERS_MODULE(state,usersModule){
      state.users_module = usersModule;
    },
    SET_SCHOOL_ID(state,school_id){
      state.school_id = school_id;
    }
  },
  actions: {
    lessonPlans({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_lessonPlans_LOAD', true);
        lessonPlans.list().then(response => {
          commit('SET_lessonPlans', response.lessonPlans);
          commit('SET_lessonPlans_LOAD', false);
          resolve();
        });
      });
    },
    editSchool({ commit }, payload) {
      return new Promise((resolve, reject) => {
        lessonPlans
          .editSchool(payload.id,payload.query)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    inactiveSchool({ commit }, id) {
      return new Promise((resolve, reject) => {
        lessonPlans
          .inactiveSchool(id)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    createSchool({ commit }, payload) {
      return new Promise((resolve, reject) => {
        lessonPlans
          .createSchool(payload.query)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    get({ commit }, id) {
      return new Promise((resolve, reject) => {
        lessonPlans.get(id)
          .then(response => {
            commit('SET_SCHOOL', response.data.school);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    users_lookup({ commit },id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_USER_LOOKUPS',true);
        lessonPlans.users_lookup(id)
          .then(response => {
            commit('SET_USERS_SCHOOL', response.users_school);
            commit('SET_USERS_MODULE', response.users_module);
            commit('SET_SCHOOL_ID', response.school_id);
            commit('SET_LOAD_USER_LOOKUPS',false);

            resolve(response);
          })
          .catch(error => {
            reject(error);
          commit('SET_LOAD_USER_LOOKUPS',false);

          });
      });
    },
    assignUsers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_ASSIGN',true)
        lessonPlans
          .assignUsers(payload.id,payload.query)
          .then(response => {
            resolve(response);
            commit('SET_LOAD_ASSIGN',false)

          })
          .catch(error => {
            reject(error);
            commit('SET_LOAD_ASSIGN',false)

          });
      });
    },
    answerQue({ commit }, payload) {
      return new Promise((resolve, reject) => {
        
        commit('SET_LOAD_SUBMIT', true);

        lessonPlans
          .answerQue(payload)
          .then(response => {
            resolve(response);
          commit('SET_LOAD_SUBMIT', false);

          })
          .catch(error => {
            reject(error);
            commit('SET_LOAD_SUBMIT', false);

          });
      });
    },
    finishTopic({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_FINISH_LOAD',true)
        lessonPlans
          .finishTopic(payload)
          .then(response => {
            commit('SET_FINISH_DATA',response)
            commit('SET_FINISH_LOAD',false)
            resolve(response);
          })
          .catch(error => {
            reject(error);
            commit('SET_FINISH_LOAD',false)
          });
      });
    },
    answerOpenQuestions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_SUBMIT', true);
        lessonPlans
          .answerOpenQuestions(payload)
          .then(response => {
            resolve(response);
            commit('SET_LOAD_SUBMIT', false);
          })
          .catch(error => {
            reject(error);
            commit('SET_LOAD_SUBMIT', false);

          });
      });
    },
    AttachPDFAnswer({ commit }, payload) {
      return new Promise((resolve, reject) => {
        lessonPlans
          .AttachPDFAnswer(payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    enrolUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        lessonPlans
          .enrolUser(id)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    remove({ commit }, id) {
      return new Promise((resolve, reject) => {
        lessonPlans
          .remove(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
};

export default lessonPlansModule;
