import StudentDashboard from '@/api/studentDashboard/studentDashboard';
import fileDownload from "js-file-download";
import router from "@/router";

const studentDashboard = new StudentDashboard();

const studentDashboardModule = {
  namespaced: true,
  state: {
    statistics: [],
    recommended:[],
    loadStatistics:false,
    loadRecommended:false,
  },
  getters: {
    statistics(state) {
      return state.statistics;
    },
    recommended(state) {
      return state.recommended;
    },
    loadStatistics(state) {
      return state.loadStatistics;
    },
    loadRecommended(state) {
      return state.loadRecommended;
    },
    
  },
  mutations: {
    SET_STATISTICS: (state, statistics) => {
      state.statistics = statistics;
    },
    SET_RECOMMENDED: (state, recommended) => {
      state.recommended = recommended;
    },
    SET_LOAD_STATISTICS: (state, loadStatistics) => {
      state.loadStatistics = loadStatistics;
    },
    SET_LOAD_RECOMMENDED: (state, loadRecommended) => {
      state.loadRecommended = loadRecommended;
    },
  },
  actions: {
    studentStatistics({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_STATISTICS', true);
        studentDashboard.studentStatistics().then(response => {
          commit('SET_STATISTICS', response.statistics);
          commit('SET_LOAD_STATISTICS', false);
          resolve();
        }).catch(error => {
          reject(error);
          commit('SET_LOAD_STATISTICS', false);
        });
      });
    },
    recommendedQuests({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_RECOMMENDED', true);
        studentDashboard.recommendedQuests().then(response => {
          commit('SET_RECOMMENDED', response.recommended);
          commit('SET_LOAD_RECOMMENDED', false);
          resolve();
        }).catch(error => {
          reject(error);
          commit('SET_LOAD_RECOMMENDED', false);
        });
      });
    },
  },
};

export default studentDashboardModule;
