export default [
  {
    path: '/bulk_certificates',
    name: 'bulkCertificates',
    component: () => import('@/views/modules/bulk_certificates/bulk_certificates.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'certificates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-bulkCertificate',
    name: 'add_bulkCertificate',
    component: () => import('@/views/modules/bulk_certificates/EditBulkCertificate.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'certificates',
          to: '/bulkCertificates'
        },
        {
          text: 'Add Certificate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit-bulkCertificate/:id',
    name: 'edit_bulkCertificate',
    component: () => import('@/views/modules/bulk_certificates/EditBulkCertificate.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'certificates',
          to: '/bulkCertificates'
        },
        {
          text: 'edit_bread',
          active: true,
        },
      ],
    },
  },
  
]
