import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import schools from './routes/modules/schools'
import courses from './routes/modules/courses'
import modules from './routes/modules/modules'
import bulk_certificates from './routes/modules/bulk_certificates'
import topics from './routes/modules/topics'
import learning from './routes/modules/learning'
import users from './routes/modules/users'
import students from './routes/modules/students'
import admins from './routes/modules/admins'
import requests from './routes/modules/requests'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'learning' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...schools,
    ...courses,
    ...modules,
    ...bulk_certificates,
    ...topics,
    ...learning,
    ...users,
    ...students,
    ...admins,
    ...requests,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ], 
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (
    !isLoggedIn &&
    !to.meta.redirectIfLoggedIn &&
    to.name !== "misc-under-maintenance" &&
    to.name != 'auth-reset-password' &&
    to.name != 'personality_assessment') {
    // if (to.name != 'auth-login') {
    //   store.commit('app/SAVE_URL', to);
    // }
    return next({ name: 'auth-login' });

  }else{
    return next();
  }
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to) => {
  const isLoggedIn = isUserLoggedIn();
  if (to.name == 'auth-login' && isLoggedIn) {
    
    router.push({ name: 'dashboard' });
  }
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router
