import AdminDashboard from '../../../api/adminDashboard/adminDashboard';
import fileDownload from "js-file-download";
import router from "@/router";

const adminDashboard = new AdminDashboard();

const adminDashboardModule = {
  namespaced: true,
  state: {
    schools: [],
    loadSchools:false,
  },
  getters: {
    schools(state) {
      return state.schools;
    },
    loadSchools(state) {
      return state.loadSchools;
    },
    
  },
  mutations: {
    SET_SCHOOLS: (state, schools) => {
      state.schools = schools;
    },
    SET_LOAD_SCHOOLS: (state, loadSchools) => {
      state.loadSchools = loadSchools;
    },
  },
  actions: {
    topSchools({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_SCHOOLS', true);
        adminDashboard.topSchools().then(response => {
          commit('SET_SCHOOLS', response.schools);
          commit('SET_LOAD_SCHOOLS', false);
          resolve();
        }).catch(error => {
          reject(error);
          commit('SET_LOAD_SCHOOLS', false);
        });
      });
    },
  },
};

export default adminDashboardModule;
