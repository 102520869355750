export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/admin-dashboard',
    name: 'adminDashboard',
    component: () => import('@/views/dashboard/adminDashboard.vue'),
  },
  {
    path: '/hod-dashboard',
    name: 'HODDashboard',
    component: () => import('@/views/dashboard/HODDashboard.vue'),
  },
  
]
