export default [
  {
    path: '/requests',
    name: 'requests',
    component: () => import('@/views/modules/requests/Requests.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'requests',
          active: true,
        },
      ],
    },
  },
]
