import Resource from '@/api/resource';
import request from '@/utils/request';
import store from "@/store/index";

class StudentDashboard extends Resource {
    constructor() {
        super('studentDashboard');
    }

    studentStatistics(){
        return request({
            url: '/stdDashboard/studentStatistics',
            method: 'post'
        })
    }
    recommendedQuests(){
        return request({
            url: '/stdDashboard/recommendedQuests',
            method: 'post'
        })
    }

}

export {StudentDashboard as default};
