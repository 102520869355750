import Resource from '../../api/resource';
import request from '../../utils/request';

class Requests extends Resource {
    constructor() {
        super('requests')
    }

    list(query) {
        return request({
            url: `/planLesson/requests`,
            method: 'post',
            data: query
        })
    }   

    requestAction(query) {
        return request({
            url: `/planLesson/requestAction`,
            method: 'post',
            data: query,
        })
    }

}

export {Requests as default};
