import Resource from '@/api/resource';
import request from '@/utils/request';
import store from "@/store/index";

class AdminDashboard extends Resource {
    constructor() {
        super('adminDashboard');
    }

    topSchools(){
        return request({
            url: '/adminDashboard/topSchools',
            method: 'post'
        })
    }

}

export {AdminDashboard as default};
