import Resource from '../../api/resource';
import request from '../../utils/request';

class Notifications extends Resource {
    constructor() {
        super('notifications')
    }

    list(query) {
        return request({
            url: `/Notification/index`,
            method: 'post',
            data: query
        })
    }   

    markAllAsRead() {
        return request({
            url: `/Notification/markAllAsRead`,
            method: 'post',
        })
    }  

    markAsRead(id) {
        return request({
            url: `/Notification/markAsRead/${id}`,
            method: 'post',
        })
    }   

}

export {Notifications as default};
