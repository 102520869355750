import Resource from '@/api/resource';
import request from '@/utils/request';

class Bulk_certificates extends Resource {
    constructor() {
        super('bulk_certificates');
    }

    list(query) {
        return request({
            url: `/${this.uri}/list`,
            method: 'post',
            data: query,
        })
    }
    modulesLookups() {
        return request({
            url: `/${this.uri}/modulesLookups`,
            method: 'get',
        })
    }
    get(id) {
        return request({
            url: `/${this.uri}/show`,
            method: 'post',
            data: {id: id},
        })
    }

    store( query) {
        return request({
            url: `/${this.uri}/store`,
            method: 'post',
            data: query,
        })
    }

    lookups(){
        return request({
            url: '/lookups/courses_lookups',
            method: 'get'
        })
    }
}

export {Bulk_certificates as default};
