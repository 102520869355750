import Requests from '../../../api/requests/requests';
import fileDownload from "js-file-download";
import router from "@/router";

const requests = new Requests();

const requestsModule = {

  namespaced: true,
  state: {
    items: [],
    load: false
  },
  getters: {
    items(state) {
      return state.items;
    },
    load(state) {
      return state.load;
    },
  },
  mutations: {
    SET_ITEMS: (state, modules) => {
      state.items = modules;
    },
    SET_ITEMS_LOAD: (state, load) => {
      state.load = load;
    },
  },
  actions: {
    list({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        requests.list(payload).then(response => {  
          commit('SET_ITEMS', response.requests);
          commit('SET_ITEMS_LOAD', false);
          resolve();
        });
      });
    },
    requestAction({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        requests.requestAction(payload).then(response => {          
          commit('SET_ITEMS_LOAD', false);
          resolve();
        });
      });
    },
  }
}

export default requestsModule;
